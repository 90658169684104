import React from "react"
import ReactHtmlParser from "react-html-parser"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { WithWaypoint } from "../utils/HOCs"
import { siteMetaCtx } from "../store/siteMeta/context"
import { RoomFullPage } from "../components/RoomPage/RoomFullPage"

import { Breadcrumb } from "../ui/Breadcumb"

function getWaypointEffect() {
  return {
    effect: {
      activeStyle: {
        opacity: 1,
        transform: "translateY(0)",
        transition: "opacity 1.2s ease-in-out, transform .8s ease-in-out",
      },
      inactiveStyle: {
        opacity: 0,
        transform: "translateY(40px)",
      },
    },
  }
}

const AboutUsTemplate = ({ pageContext, uri, ...props }) => {
  const { full_page, basic_info, content, ...restData } = pageContext.acf

  const BodyWithWaypoint = WithWaypoint(
    React.forwardRef((props, ref) => (
      <section ref={ref} className="position-relative">
        <div className="fixed-top h-100 body-img" style={{ top: 100 }}></div>
        <div className="about-us-body">
          <Breadcrumb
            paths={basic_info.breadcumb.map(path => ({
              text: path.name,
              link_url: path.link_name,
            }))}
          />
          <div className="wrapper">
            {content.sections.map((section, idx) => (
              <div className="row mb-5 two-block-section" key={idx}>
                <div className="col-24 order-1 col-md-12 order-md-0 p-0 my-2 px-md-2 my-md-0 html-parser-block">
                  {ReactHtmlParser(section.content_left)}
                </div>
                <div className="col-24 order-0 col-md-12 order-md-1 p-0 my-2 px-md-2 my-md-0  html-parser-block">
                  {ReactHtmlParser(section.content_right)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    ))
  )

  return (
    <siteMetaCtx.Provider value={{ uri }}>
      <Layout>
        <SEO
          lang="zh-TW"
          title={basic_info.seo_title}
          description={basic_info.seo_description}
        ></SEO>
        <RoomFullPage data={full_page} />
        <BodyWithWaypoint {...getWaypointEffect()} />
      </Layout>
    </siteMetaCtx.Provider>
  )
}

export default AboutUsTemplate
